<template>
  <div class="fill-height">
    <v-container
      v-if="prefsLoaded && fetchingLastDecisionSlug"
      fluid
      class="pikle-board-wrapper"
    >
      <v-row>
        <v-col cols="12" class="pt-6">
          <v-progress-circular class="mt-6" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <LoginForm v-else @login="handleLogin" />
  </div>
</template>

<script>

import AnalyticsUtil from '../analyticsUtil'
import { logEvent } from "firebase/analytics";

import LoginForm from '@/components/Common/LoginForm'

import { mapState } from 'vuex'

// Veux types
import * as DecisionsTypes from '@/store/types/decisions'

import slug from 'slug'

// import { validationMixin } from 'vuelidate'
// import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'PikleLogin',
  components: {
    LoginForm
  },
  // mixins: [validationMixin],
  data () {
    return {
      fetchingLastDecisionSlug: false
    }
  },
  // validations: {
  //   email: { required, email },
  //   password: { required }
  // },
  computed: {
    ...mapState({
      lastDecision: state => state.user.lastDecision,
      currentCountryCode: state => state.user.currentCountry,
      scrapingRegions: state => state.user.scrapingRegions,
      prefsLoaded: state => state.user.loaded
    }),
  //   failedVerification () {
  //     return this.verificationFailed || this.$route.query.failedVerification === 'true'
  //   },
  //   emailErrors () {
  //     const errors = []
  //     if (!this.$v.email.$dirty) return errors
  //     !this.$v.email.email && errors.push('Must be valid email')
  //     !this.$v.email.required && errors.push('Email is required.')
  //     return errors
  //   },
  //   passwordErrors () {
  //     const errors = []
  //     if (!this.$v.password.$dirty) return errors
  //     !this.$v.password.required && errors.push('Password is required.')
  //     return errors
  //   }
  },
  methods: {
    handleLogin: async function () {
      // console.log(`Got a fb user ${user}`)
      // Redirect to the origional from url unless it was the login route itself
      // This can happen if the login succeeds but then the use is redirected back to login due to lack of email veriication.
      // console.log(`From ${this.$route.query.from}`)
      // console.log(`Handling login: last decision = ${this.lastDecision}`)

      
      logEvent(AnalyticsUtil.analytics, 'login', {
        method: "Email/Password"
      })
      // firebase.analytics().logEvent('login', {
      //   method: "Email/Password"
      // });

      const selectedRegion = this.scrapingRegions.find(next => next.country === this.currentCountryCode)

      if (!selectedRegion) {
        this.$router.replace('/location')
      } else {
        
        let userDecisionNameSlug = null
        if (this.lastDecision) {
          this.fetchingLastDecisionSlug = true
          const userDecisions = await this.$store.dispatch(`decisions/${DecisionsTypes.ACTION_LOAD_DECISIONS_MAP}`)
          if (userDecisions && userDecisions[this.lastDecision.id] && userDecisions[this.lastDecision.id].name) {

            userDecisionNameSlug = slug(userDecisions[this.lastDecision.id].name)
            // this.$router.replace(`/pikles/${nameSlug}`)

          }
        }

        if (userDecisionNameSlug) {
          this.$router.replace(`/pikles/${userDecisionNameSlug}`)
        }
        else {
          // this.$router.replace('/discover/laptops')
          // this.$router.replace(`/${selectedRegion.id}/discover`)
          this.$router.replace(`/pikles`)
        }
      }


      // if (this.$route.query.from && this.$route.query.from !== '/login') {
      //   this.$router.replace(this.$route.query.from)
      // } else {
      //   this.$router.replace('/pikles')
      // }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-container {
  background: #0e2887;
  /*background-image: url("IMAGE_URL"); fallback */
  background: linear-gradient(#3a52b5, #0e2887); /* W3C */
  /*background-image: linear-gradient(#3a52b5, #0e2887);*/
  color: white;
}
.comparison-list {
  display: flex;
  justify-content: space-around;
}
.logo-title {
  color: #1e40aa;
  text-transform: uppercase;
}
</style>
