<template>
  <v-container
    fluid
    class="login-container fill-height align-start"
  >
    <v-row align="center" justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
        class="pt-6"
      >
        <v-form @submit.prevent="validateLoginForm" class="mt-6">
          <v-card class="px-4">
            <v-card-title class="d-flex align-center justify-center">
              <div class="text-left">
                <div>
                  <router-link to="/">
                  <v-img
                    alt="Pikle Logo"
                    :src="require('../../assets/logo-full.svg')"
                    max-width="90"
                  ></v-img>
                  </router-link>
                </div>
                <div class="mt-0">
                  <div class="text-caption">
                    BETA
                  </div>
                </div>
              </div>
            </v-card-title>
            <v-card-text>

              <v-text-field
                v-model="email"
                label="email"
                name="login"
                prepend-icon="mdi-account"
                type="email"
                :error-messages="emailErrors"
                @blur="$v.email.$touch()"
              ></v-text-field>

              <v-text-field
                id="password"
                v-model="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                :error-messages="passwordErrors"
                @blur="$v.password.$touch()"
              ></v-text-field>

            </v-card-text>
            <v-card-actions>
              <v-btn
                block
                type="submit"
                large
                class="primary-action-btn"
                color="primary"
                :disabled="$v.email.$invalid || $v.password.$invalid || working"
              >
                {{working ? 'Working...' : 'Sign In'}}
              </v-btn>
            </v-card-actions>
            <div class="pa-6 text-caption text-left">
              By signing in, you agree to our <router-link to="/terms" target="_blank">Terms of Service</router-link> and <router-link to="/privacy" target="_blank">Privacy Statement</router-link>.
            </div>
            <div class="px-6 pb-6 text-caption text-left">
              Forgot your password?
              <a
                href="/resetpassword"
              >
                You can reset your password here
              </a>.
            </div>
          </v-card>
          <div v-if="failedVerification" class="mt-4 text-left">
            <div v-if="verificationSent">
              <v-alert
                dense
                type="success"
              >
                Thanks! We sent you a new verification email.
              </v-alert>
            </div>
            <div v-else class="verification-message-wrapper">
              <v-alert
                dense
                type="error"
              >
                You still need to verify your email address.
              </v-alert>
              <v-alert
                dense
                type="info"
              >
                Check your email including spam folder for a verification email.<br><br>
                If the verification link has expired or you cannot find the email you can request a new one.
                <div class="my-2">
                  <v-btn
                    light
                    small
                    :disabled="working"
                    :loading="working"
                    @click="resendVerification()"
                  >
                    request a new verification email
                  </v-btn>
                </div>
              </v-alert>
            </div>
          </div>
          <div v-if="!failedVerification && attempts > 0" class="mt-4 text-left">
            <v-alert
              dense
              type="error"
            >
              Incorrect email or password. If you forgot you password you can reset your password
              <div class="my-2">
                <v-btn
                  light
                  small
                  :disabled="working"
                  :loading="working"
                  href="/resetpassword"
                >
                  reset your password
                </v-btn>
              </div>
            </v-alert>
          </div>
        </v-form>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import AuthUtil from '../../authUtil'

import { signInWithEmailAndPassword, signOut, sendEmailVerification } from "firebase/auth";

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

// Veux types
import * as UserTypes from '@/store/types/user'

export default {
  name: 'LoginForm',
  components: {
  },
  mixins: [validationMixin],
  data () {
    return {
      email: '',
      password: '',
      working: false,
      verificationSent: false,
      verificationFailed: false,
      errors: [],
      attempts: 0
    }
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  computed: {
    failedVerification () {
      return this.verificationFailed || this.$route.query.failedVerification === 'true'
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid email')
      !this.$v.email.required && errors.push('Email is required.')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required.')
      return errors
    }
  },
  methods: {
    validateLoginForm: async function () {
      // console.log(`Validate called`)
      if (
        this.email &&
        !this.$v.email.$invalid &&
        this.password &&
        !this.$v.password.$invalid
      ) {
        this.login()
      }
    },
    login: async function () {
      // console.log(`Logging in`)
      this.working = true
      this.verificationFailed = false
      let self = this
      if (AuthUtil.auth.currentUser) {
        // Sign out other user
        await signOut(AuthUtil.auth)
      }
      this.$emit('loginStart')
      signInWithEmailAndPassword(AuthUtil.auth, this.email, this.password).then(
        (user) => {
          if (user && !AuthUtil.auth.currentUser.emailVerified) {
            this.verificationFailed = true
            this.working = false
          } else {

            this.$store.dispatch(`user/${UserTypes.ACTION_HANDLE_SIGN_IN}`).then(prefs => {
              // console.log(`prefs= ${JSON.stringify(prefs)}`)
              if (prefs) {
                this.$emit('login')
              }
            })
          }
        },
        (/*error*/) => {
          self.working = false
          this.attempts++
          // console.log(error.message)
        }
      )
    },
    resendVerification: function () {
      if (AuthUtil.auth.currentUser && !AuthUtil.auth.currentUser.emailVerified) {
        const self = this
        sendEmailVerification(AuthUtil.auth.currentUser).then(function () {
        // AuthUtil.auth.currentUser.sendEmailVerification().then(function () {
          // Email sent.
          self.working = false
          self.verificationSent = true
        }).catch(function (/*error*/) {
          // An error happened
          self.working = false
          // console.log(error)
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-container {
  background: #0e2887;
  /*background-image: url("IMAGE_URL"); fallback */
  background: linear-gradient(#3a52b5, #0e2887); /* W3C */
  /*background-image: linear-gradient(#3a52b5, #0e2887);*/
  color: white;
}
.logo-title {
  color: #1e40aa;
  text-transform: uppercase;
}
</style>
