import Constants from '../constants.js'
import AuthUtil from '../authUtil'
import FirestoreUtil from '../firestoreUtil'

import { collection, doc, getDoc, query, where, orderBy, limit } from "firebase/firestore";

import axios from 'axios'

export default {
  async getOption (decisionId, optionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const optionURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}`
    return axios.get(optionURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      return response.data
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      return null
    })
  },
  async saveOptionName (decisionId, optionId, newName) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const postNewNameURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/name`
    const postNameBody = {name: newName}

    const response = await axios.post(postNewNameURL, postNameBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    return response

  },
  async saveOptionImage (decisionId, optionId, imgSrc) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const apiEndpoint = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/img`
    const apiBody = {imgSrc: imgSrc}

    const response = await axios.post(apiEndpoint, apiBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    return response

  },
  async linkOptionToAnother (decisionId, optionId, substituteId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/link`
    const postBody = {substituteId: substituteId}

    const response = await axios.post(postURL, postBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    return response

  },
  async deleteOptionLink (decisionId, optionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/link`

    const response = await axios.delete(postURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    return response

  },
  async clearOptionURL (decisionId, optionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/clearurl`

    return axios.post(postURL, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      return response.data
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      return null
    })

  },
  async validateOptionAsGroup (decisionId, optionId) {
    const res = {found: false, url: null, substituteFor: null, name: null}

    const optionDocSnapshot = await getDoc(doc(FirestoreUtil.db, `decisions/${decisionId}/options`, optionId));
    // return collection(decisionDocRef, "options")

    if (optionDocSnapshot.exists() && optionDocSnapshot.data() && !optionDocSnapshot.data().substituteFor) {
      res.found = true
      res.url = optionDocSnapshot.data().url
      res.name = optionDocSnapshot.data().name
      res.substituteFor = optionDocSnapshot.data().substituteFor
    }
    return res
  },
  async saveOptionIsApprovedRecommendation (decisionId, optionId, isApproved) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()


    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/recommendation`
    const postBody = {isApprovedRecommendation: isApproved}

    const response = await axios.post(postURL, postBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    return response

  },
  async setShortListed (decisionId, optionId, isShortListed) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/shortlisted/${isShortListed ? 'true' : 'false'}`
    return axios.post(postURL, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      return (response && response.status === 200 && response.data && response.data.status === 'success')
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      // callback(false)
      return false
    })
  },
  async generateShortListSummary (decisionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/shortlist/summarize`
    const summaryResponse = await axios.post(postURL, {save: true}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    // handle success
    let res = ""
    if (summaryResponse && summaryResponse.status === 200 && summaryResponse.data) {
      res =  summaryResponse.data.summary || ""
    }
    return res
  },
  async generateProductSummary (decisionId, optionId, includeCons, saveResult) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/summarize`
    const summaryResponse = await axios.post(postURL, {save: saveResult, includeCons: includeCons}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    // handle success
    let res = ""
    if (summaryResponse && summaryResponse.status === 200 && summaryResponse.data) {
      res =  summaryResponse.data.summary || ""
    }
    return res
  },
  async saveProductSummary (decisionId, optionId, summaryText) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/summary`
    const summaryResponse = await axios.post(postURL, {summaryText: summaryText}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    // handle success
    let res = ""
    if (summaryResponse && summaryResponse.status === 200 && summaryResponse.data) {
      res =  summaryResponse.data.status === 'success' || false
    }
    return res
  },
  async approveProductSummary (decisionId, optionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/approvesummary`
    const summaryResponse = await axios.post(postURL, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    // handle success
    let res = ""
    if (summaryResponse && summaryResponse.status === 200 && summaryResponse.data) {
      res =  summaryResponse.data.status === 'success' || false
    }
    return res
  },
  async deleteShortListSummary (decisionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/shortlist/summary`
    return await axios.delete(postURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  },
  async generateCriteriaSummary (decisionId, criteriaId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/criteria/${criteriaId}/summarize`
    const summaryResponse = await axios.post(postURL, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    // handle success
    let res = ""
    if (summaryResponse && summaryResponse.status === 200 && summaryResponse.data) {
      res =  summaryResponse.data.summary || ""
    }
    return res
  },
  async approveOptionValues (decisionId, optionId, payload) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const approveOptionValuesURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/approve`

    const res = await axios.post(approveOptionValuesURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200) {
      return true
    } else {
      return false
    }
  },
  async clearRescrapeValues (decisionId, optionId, payload) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const approveOptionValuesURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/rescrape/clear`

    const res = await axios.post(approveOptionValuesURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200) {
      return true
    } else {
      return false
    }
  },
  async saveOptionValues (decisionId, optionId, criteriaValues, removeValueOptions, recordLatestPrice) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postCriteriaValuesURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/criteria`
    const postBody = {criteriaValues: criteriaValues, removeValueOptions: removeValueOptions, recordLatestPrice: recordLatestPrice}

    const res = await axios.post(postCriteriaValuesURL, postBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data && res.data.status === "success") {
      return true
    } else {
      return false
    }
  },
  async deleteOption (decisionId, optionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteOptionURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}`

    const res = await axios.delete(deleteOptionURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data && res.data.status === "success") {
      return true
    } else {
      // console.log(`Unexpected response ${JSON.stringify(res)}`)
      // TODO LOG
      return false
    }
  },
  async scrapeOption (decisionId, optionId, url, scrapeCriteriaList, uid, taxonRegion, taxonId, scraperRegion, useStableHTML) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postResetScrapeStateURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/criteria/scrape`

    const scrapeBody = {
      url: encodeURI(url),
      criteria: scrapeCriteriaList
    }
    if (uid) {
      scrapeBody.uid = uid // used to find user specific synonyms
    }
    if (taxonRegion) {
      scrapeBody.taxonRegion = taxonRegion
    }
    if (scraperRegion) {
      scrapeBody.scraperRegion = scraperRegion
    }
    if (useStableHTML) {
      scrapeBody.useStableHTML = useStableHTML
    }
    if (taxonId) {
      scrapeBody.taxon = taxonId
    }

    const res = await axios.post(postResetScrapeStateURL, scrapeBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200) {
      return true
    } else {
      return false
    }
  },
  async scrapeImages (decisionId, optionId, url, scraperRegion, useStableHTML) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const imageScrapeURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/image/scrape`

    const imageScrapeBody = {
      url: encodeURI(url),
    }

    if (scraperRegion) {
      imageScrapeBody.scraperRegion = scraperRegion
    }
    if (useStableHTML) {
      imageScrapeBody.useStableHTML = useStableHTML
    }

    return await axios.post(imageScrapeURL, imageScrapeBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

  },
  async scrapeMetaData (decisionId, optionId, url, scraperRegion, useStableHTML) {

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const scrapeURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/metadata/scrape`

    const scrapeBody = {
      url: encodeURI(url),
    }

    if (scraperRegion) {
      scrapeBody.scraperRegion = scraperRegion
    }
    if (useStableHTML) {
      scrapeBody.useStableHTML = useStableHTML
    }

    return await axios.post(scrapeURL, scrapeBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

  },
  async scrapeSingleCriteria (decisionId, optionId, url, criteriaItems, uid, taxonRegion, scraperRegion, useStableHTML) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postSingleValueScrapeURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/criteria/scrape`

    const valuesOnlyScrapeBody = {
      url: encodeURI(url),
      criteria: criteriaItems,
      valuesOnlyScrape: true
    }
    
    if (uid) {
      valuesOnlyScrapeBody.uid = uid // used to find user specific synonyms
    }
    if (taxonRegion) {
      valuesOnlyScrapeBody.taxonRegion = taxonRegion
    }
    if (scraperRegion) {
      valuesOnlyScrapeBody.scraperRegion = scraperRegion
    }
    if (useStableHTML) {
      valuesOnlyScrapeBody.useStableHTML = useStableHTML
    }

    // this.scraping = true
    // console.log(`Calling values only scrape with body ${JSON.stringify(valuesOnlyScrapeBody)}`)
    return await axios.post(postSingleValueScrapeURL, valuesOnlyScrapeBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

  },
  async checkURLHash (decisionId, optionId, url, ignoreExpiry, scraperRegion, useStableHTML) {
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const checkOptionURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/urlcheck`

    // const expireTime = ignoreExpiry ? 1000 : 7200000; /* Ingoring means hash expires after 1 second. Default is after 2 hours */
    const expireTime = ignoreExpiry ? 1000 : 43200000; /* Ingoring means hash expires after 1 second. Default is after 12 hours */
    const checkOptionPayload = {expireTimeMS: expireTime}

    // It is possible to call urlcheck on an option which is being used as dataRef and which links to options that have URLs.
    // The linked options will be checked by urlcheck
    if (url) {
      checkOptionPayload.url = encodeURI(url)
    }
    if (scraperRegion) {
      checkOptionPayload.scraperRegion = scraperRegion
    }
    if (useStableHTML) {
      checkOptionPayload.useStableHTML = useStableHTML
    }
    return await axios.post(checkOptionURL, checkOptionPayload, {
      headers: {
        'Content-Type': 'application/json',
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  },
  async getLatestComment (decisionId, optionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const latestCommentUrl = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/comments/latest`
    return axios.get(latestCommentUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      return response.data
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      return []
    })
  },
  async getReasons (decisionId, optionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const reasonsURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/reasons`
    return axios.get(reasonsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      return response.data
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      return []
    })
  },
  async createReason (decisionId, optionId, reason) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postReasonURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/reasons`

    // console.log(`Calling save option reason ${JSON.stringify(postBody)}`)
    const res = await axios.post(postReasonURL, reason, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data && res.data.status === "success") {
      // this.optionReasons[newReasonIndex].id = res.data.reasonId
      return {id: res.data.reasonId, ...reason}
    } else {
      return null
    }
  },
  async deleteReason (decisionId, optionId, reasonId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteReasonURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/reasons/${reasonId}`
    const res = await axios.delete(deleteReasonURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    return (res && res.status === 200 && res.data && res.data.status === "success")
  },

  async deleteProductSummary (decisionId, optionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/summary`
    const res = await axios.delete(postURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    return (res && res.status === 200 && res.data && res.data.status === "success")
  },
  async getOptionSubstitutes (decisionId, optionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const reasonsURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/substitutes`
    return axios.get(reasonsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      return response.data
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      return []
    })
  },
  async updateOptionURLs (decisionId, optionId, urls) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const updateURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/urls`
    const postBody = {urls: urls}

    const res = await axios.post(updateURL, postBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data && res.data.status === "success") {
      return true
    } else {
      return false
    }
  },
  async saveComment (decisionId, optionId, parentId, comment, uid, userName) {
    // interface OptionComment {
    //   optionId: string;
    //   authorId: string;
    //   authorName: string;
    //   parentId: string; // Used for replying to another comment. Parent ID is another comment ID or option ID for 'root' so we can query on top level.
    //   comment: string;
    // }
    // router.post('/decisions/:decision/options/:option/comments'

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const updateURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/comments`
    
    const commentBody = {
      optionId: optionId,
      authorId: uid,
      authorName: userName,
      comment: comment
    }
    if (parentId) {
      commentBody.parentId = parentId
    }

    const res = await axios.post(updateURL, commentBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data && res.data.status === "success") {
      return res.data.commentId
    } else {
      return null
    }
  },
  async saveBoardComment (decisionId, parentId, comment, uid, userName) {
    // interface DecisionComment {
    //   decisionId: string;
    //   authorId: string;
    //   authorName: string;
    //   parentId: string; // Used for replying to another comment. Parent ID is another comment ID or option ID for 'root' so we can query on top level.
    //   comment: string;
    // }
    // router.post('/decisions/:decision/comments'

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const updateURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/comments`
    
    const commentBody = {
      authorId: uid,
      authorName: userName,
      comment: comment
    }
    if (parentId) {
      commentBody.parentId = parentId
    }

    const res = await axios.post(updateURL, commentBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data && res.data.status === "success") {
      return res.data.commentId
    } else {
      return null
    }
  },
  optionCommentsRef (decisionId, optionId, docLimit) {
    // let ref = firebase.firestore().collection("decisions").doc(decisionId).collection("researchDocuments")
    // return ref.limit(pageSize)
    return query(collection(FirestoreUtil.db, `decisions/${decisionId}/optionComments`), where('optionId', '==', optionId), orderBy("createdAt", "desc"), limit(docLimit))
  },
  allOptionCommentsRef (decisionId, docLimit) {
    // let ref = firebase.firestore().collection("decisions").doc(decisionId).collection("researchDocuments")
    // return ref.limit(pageSize)
    return query(collection(FirestoreUtil.db, `decisions/${decisionId}/optionComments`), orderBy("createdAt", "desc"), limit(docLimit))
  },
  decisionCommentsRef (decisionId, docLimit) {
    // let ref = firebase.firestore().collection("decisions").doc(decisionId).collection("researchDocuments")
    // return ref.limit(pageSize)
    return query(collection(FirestoreUtil.db, `decisions/${decisionId}/decisionComments`), orderBy("createdAt", "desc"), limit(docLimit))
  }
}
