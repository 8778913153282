import AuthUtil from './authUtil'

// Import vue stuff
import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/Login'
import ResetPassword from '@/views/ResetPassword'
import Home from '@/views/Home'
// import HomeAuthed from '@/views/HomeAuthed'
import Underconstruction from '@/views/Underconstruction'
import Privacy from '@/views/Privacy'
import Terms from '@/views/Terms'
import NotFound from '@/views/404.vue'
import Forbidden from '@/views/403.vue'
// import PiklesIndex from '@/views/Pikles.vue'

// Veux types
import * as UserTypes from '@/store/types/user'

import store from './store' // your vuex store 

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/mission',
      name: 'Mission Statement',
      meta: {
        title: 'Pikle - Mission Statement',
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/Mission.vue')
    },
    // {
    //   path: '/about',
    //   name: 'HomeAuthed',
    //   component: HomeAuthed,
    //   meta: {
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/register/alpha',
      name: 'Register',
      component: () => import(/* webpackChunkName: "about" */ '@/views/RegisterEcommerce.vue')
    },
    {
      path: '/register/beta',
      name: 'Register',
      meta: {
        title: 'Pikle - Register'
      },
      component: () => import(/* webpackChunkName: "about" */ '@/views/Register.vue')
    },
    {
      path: '/login',
      name: 'Login View',
      meta: {
        title: 'Pikle - Login'
      },
      component: Login
    },
    {
      path: '/signin',
      name: 'Sign In',
      component: Login
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import(/* webpackChunkName: "about" */ '@/views/Logout.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/signout',
      name: 'Sign out',
      component: () => import(/* webpackChunkName: "about" */ '@/views/Logout.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/resetpassword',
      name: 'Reset Password',
      meta: {
        title: 'Pikle - Reset Password'
      },
      component: ResetPassword
    },
    {
      path: '/wip',
      name: 'Underconstruction',
      component: Underconstruction,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/privacy',
      name: 'Privacy',
      meta: {
        title: 'Pikle - Privacy Statement'
      },
      component: Privacy
    },
    {
      path: '/terms',
      name: 'Terms',
      meta: {
        title: 'Pikle - Terms of Service'
      },
      component: Terms
    },
    {
      path: '/developer',
      name: 'Developer',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/Developer.vue')
    },
    {
      path: '/signin/ecommerce',
      name: 'Ecommerce Sign In',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/EcommerceSignIn.vue')
    },
    {
      path: '/discover',
      name: 'Discover Regional Redirect',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/DiscoverRegionalRedirect.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/:countryCode/discover',
      name: 'Discover',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/Discover.vue'),
      meta: {
        requiresAuth: true,
        showAppBar: true,
        title: 'Pikle - Discover'
      }
    },
    {
      path: '/:countryCode/discover/:taxonId',
      name: 'Taxon Home',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/TaxonHomepage.vue'),
      meta: {
        requiresAuth: true,
        showAppBar: true
      }
    },
    {
      path: '/:countryCode/pikle/:taxonId',
      name: 'Discover Regional Taxon',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/RegionalPublicTaxon.vue'),
      meta: {
        requiresAuth: true,
        showAppBar: true
      }
    },
    {
      path: '/review/:decisionId/',
      name: 'Public Pikle Board',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/PublicPikleBoard.vue'),
      meta: {
        requiresAuth: true,
        showAppBar: false
      }
    },
    {
      path: '/location',
      name: 'ChooseLocation',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/ChooseLocation.vue'),
      meta: {
        requiresAuth: true,
        title: 'Pikle - Choose Region'
      }
    },
    {
      path: '/pikles',
      name: 'Pikles Index',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/Pikles.vue'),
      meta: {
        requiresAuth: true,
        showAppBar: true,
        title: 'Pikle - My Pikles'
      }
    },
    {
      path: '/pikles/:slug',
      name: 'PikleBoard',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/PikleBoard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/u/:uslug/pikles/:pslug',
      name: 'Shared PikleBoard',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/PikleBoardShared.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/open/:decisionId',
      name: 'PikleBoardDeepLink',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/PikleBoardDeepLink.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/account',
      name: 'User Details',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/UserDetails.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/comparisons/:taxonId',
      name: 'PikleAppComparisonBoard',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/AppComparisonBoard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/comparisons/:taxonId/approve',
      name: 'PikleAppOptionReviewBoard',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/AppOptionReviewBoard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/:taxonId/recommendations',
      name: 'PikleRecommendationsReviewBoard',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/RecommendationsReviewBoard.vue'),
      meta: {
        requiresAuth: true,
        requiresSysAdmin: true
      }
    },
    {
      path: '/forbidden',
      component: Forbidden
    },
    {
      path: '*',
      component: NotFound
    }
  ]
})

// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
//   const requiresSysAdmin = to.matched.some(record => record.meta.requiresSysAdmin)

//   // Require authenticated users
//   if (requiresAuth) {
//     const currentUser = AuthUtil.auth.currentUser

//     // Initialize user store regardless of email verified status.
//     if (currentUser && !router.app.$store.state.user.loaded) {
//       router.app.$store.dispatch(`user/${UserTypes.ACTION_LOAD_PREFERENCES}`).then(prefs => {
//         if (prefs && prefs.isAnonymous) {
//           // For now redirect anonymous users to the homepage. They are only allowed access via the Plugins router.
//           // In the future we can redirect them to sign up, or give them limited access.
//           router.replace('/')
//         }
//         else if (requiresSysAdmin && (!prefs || !prefs.isSysAdmin)) {
//           router.replace('/')
//         }
//         else if (to.name !== 'ChooseLocation' && !prefs.regionSupported) {
//           router.replace('/location')
//         }
//       })
//     }

//     // Require verified emails to go further
//     if (currentUser && currentUser.emailVerified) {
//       next()
//     } else  {
//       const forwardPath = window.location.pathname
//       next({ path: '/login', query: { from: forwardPath, failedVerification: (currentUser ? !currentUser.emailVerified : false) } })
//     }
//   } else {
//     next()
//   }
// })

router.beforeEach((to, from, next) => {
  
  document.title = to.meta && to.meta.title ? to.meta.title : 'Pikle';

  const currentUser = AuthUtil.auth.currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresSysAdmin = to.matched.some(record => record.meta.requiresSysAdmin)

  if (currentUser && !store.state.user.loaded) {
    // Load user details to check if they are marked as a developer
    store.dispatch(`user/${UserTypes.ACTION_LOAD_PREFERENCES}`)
  }
  
  if (requiresAuth) {

    // Initialize user store regardless of email verified status.
    if (currentUser && !store.state.user.loaded) {
      store.dispatch(`user/${UserTypes.ACTION_LOAD_PREFERENCES}`).then(prefs => {
        if (requiresSysAdmin && (!prefs || !prefs.isSysAdmin)) {
          router.replace('/')
        }
      })
    }

    if (currentUser && currentUser.emailVerified) {
      next()
    } else  {
      const forwardPath = window.location.pathname
      next({ path: '/login', query: { from: forwardPath, failedVerification: (currentUser ? !currentUser.emailVerified : false) } })
    }
  } else {
    next()
  }
})

export default router
