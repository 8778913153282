<template>
  <div class="fill-height">
    <v-container
      fluid
      class="login-container fill-height align-start"
    >
      <v-row align="center" justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
          class="pt-6"
        >
          <v-form @submit.prevent="handlePasswordReset" class="mt-6">
            <v-card class="px-4 pb-6">
              <v-card-title class="d-flex align-center justify-center">
                <div class="text-left">
                  <div>
                    <router-link to="/">
                    <v-img
                      alt="Pikle Logo"
                      :src="require('../assets/logo-full.svg')"
                      max-width="90"
                    ></v-img>
                    </router-link>
                  </div>
                  <div class="mt-0">
                    <div class="text-caption">
                      BETA
                    </div>
                  </div>
                </div>
              </v-card-title>
              <v-card-text>

                <v-text-field
                  v-model="email"
                  label="email address"
                  name="reset"
                  prepend-icon="mdi-account"
                  type="email"
                  :error-messages="emailErrors"
                  @blur="$v.email.$touch()"
                ></v-text-field>

              </v-card-text>
              <v-card-actions>
                <v-btn
                  block
                  type="submit"
                  large
                  class="primary-action-btn"
                  color="primary"
                  :disabled="$v.email.$invalid || working || resetSent || errorMessage"
                >
                  {{buttonLabel}}
                </v-btn>
              </v-card-actions>
              <div class="pa-6 text-caption" v-if="!resetSent">
                Use the email address associated with your account and click reset password so we can send you a password reset link
              </div>
            </v-card>
            <div class="mt-4 text-left">
              <div v-if="resetSent">
                <v-alert
                  dense
                  type="success"
                >
                  Thanks! We sent you an email with a link to reset your password. Follow the instructions to reset your password once you receive it and check your spam/junk folders if you do not see it soon.
                </v-alert>
              </div>
              <div v-else-if="errorMessage">
                <v-alert
                  dense
                  type="error"
                >
                  {{ errorMessage }}
                </v-alert>
              </div>
            </div>
          </v-form>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import AuthUtil from '../authUtil'

import { sendPasswordResetEmail } from "firebase/auth";

import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'

export default {
  name: 'ResetPassword',
  mixins: [validationMixin],
  data () {
    return {
      email: null,
      resetSent: false,
      working: false,
      errorMessage: null
    }
  },
  validations: {
    email: { required, email }
  },
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid email address')
      !this.$v.email.required && errors.push('Your email address is required.')
      return errors
    },
    buttonLabel () {
      let res = 'Reset Password'
      if (this.working) {
        res = 'Working...'
      } else if (this.resetSent) {
        res = 'Thanks'
      }
      return res
    }
  },
  methods: {
    handlePasswordReset: async function () {
      this.working = true
      this.resetSent = false
      
      sendPasswordResetEmail(AuthUtil.auth, this.email)
      .then(() => {
        // Password reset email sent!
        this.working = false
        this.resetSent = true
      })
      .catch((error) => {
        this.working = false
        if (error.code) {
          if (error.code === 'auth/user-not-found') {
            this.errorMessage = 'No user with that email address was found'
          } else {
            this.errorMessage = error.code;//error.message;
          }
        }
      })
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-container {
  background: #0e2887;
  /*background-image: url("IMAGE_URL"); fallback */
  background: linear-gradient(#3a52b5, #0e2887); /* W3C */
  /*background-image: linear-gradient(#3a52b5, #0e2887);*/
  color: white;
}
.logo-title {
  color: #1e40aa;
  text-transform: uppercase;
}
</style>
