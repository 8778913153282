<template>
  <div>
    <AppToolbar :showNav="false" />
    <v-container
      fluid
    >
      <h1>Under Construction</h1>
    </v-container>
  </div>
</template>

<script>
import AppToolbar from '@/components/Navigation/AppToolbar'

export default {
  name: 'PikleUnderConstruction',
  components: {
    AppToolbar
  },
  data () {
    return {
      tab: null
    }
  },
  computed: {
      titleClass () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'coming-soon-title-sm'
          case 'sm': return 'coming-soon-title-sm'
          case 'md': return 'coming-soon-title-sm'
          case 'lg': return 'coming-soon-title'
          case 'xl': return 'coming-soon-title'
          default: return 'coming-soon-title'
        }
      },
    },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-container {
  background: #FFF;

  .coming-soon-title {
    font-size: 4rem;
    color: #6290fc;
    letter-spacing: 1rem;
    padding: 10px;

    &.ecommerce {
      background: #6290fc;
      color: #FFF;
    }
  }

  .coming-soon-title-sm {
    font-size: 2rem;
    color: #6290fc;
    letter-spacing: 1rem;
    padding: 10px;

    &.ecommerce {
      background: #6290fc;
      color: #FFF;
    }
  }
}
</style>
